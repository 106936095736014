var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{class:`project-tile-container ${_vm.projectId}`,style:({
    backgroundColor: _vm.backgroundProps.color,
    backgroundImage: _vm.backgroundProps.image,
    color: _vm.tileData.fontColor,
  }),attrs:{"to":`/${_vm.projectId}`}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"project-heading"},[_c('h1',{staticClass:"project-title"},[_vm._v(_vm._s(_vm.projectName))]),_c('h2',{staticClass:"project-subtitle"},[_vm._v(_vm._s(_vm.tileData.description))]),_c('svg',{staticClass:"arrow",attrs:{"width":"24","height":"23","viewBox":"0 0 24 23","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12.3636 22.8409L10.0227 20.5227L17.0341 13.5114H0V10.125H17.0341L10.0227 3.125L12.3636 0.795454L23.3864 11.8182L12.3636 22.8409Z","fill":"currentColor"}})])])]),(_vm.backgroundProps.image !== '' || _vm.backgroundProps.imageMobile !== '')?_c('picture',{staticClass:"background-image"},[(_vm.backgroundProps.imageMobile !== '')?_c('source',{attrs:{"media":"(max-width: 768px)","srcset":require(`@/assets/images/${_vm.backgroundProps.imageMobile}`)}}):_vm._e(),(_vm.backgroundProps.image !== '')?_c('source',{attrs:{"media":"(min-width: 769px)","srcset":require(`@/assets/images/${_vm.backgroundProps.image}`)}}):_vm._e(),(
        _vm.backgroundProps.image !== '' || _vm.backgroundProps.imageMobile !== ''
      )?_c('img',{style:({
        'object-position': _vm.backgroundProps.position,
      }),attrs:{"src":"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}}):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }