<template>
  <div class="home-content center-wrapper-content">
    <section class="grid-wrapper">
      <div class="home-top-content">
        <div class="hero-text">
          <h1>
            Hello, I’m Michel.
            <br />
            Digital Product Design is what I do.
          </h1>
        </div>
        <div class="site-description">
          <p class="medium-text">
            Currently I'm working at KIMO
            <br />
            building the future of education.
          </p>
        </div>
      </div>
    </section>

    <section id="projects" class="project-tiles">
      <ProjectTile
        v-for="project in projectdata"
        v-bind:key="project.projectId"
        v-bind:projectId="project.projectId"
        v-bind:projectName="project.projectName"
        v-bind:projectSubtitle="project.projectSubtitle"
        v-bind:tileData="project.projectTile"
      />
    </section>

    <section id="approach" class="grid-wrapper approach-block">
      <div class="content-column">
        <div class="content-block">
          <p>
            I can help you improve your current product
            <br class="desktop-break" />or create a new product from scratch.
          </p>
        </div>
      </div>
    </section>

    <section id="toolbox" class="grid-wrapper toolbox-block">
      <div>
        <h2 class="title bold">My Toolbox</h2>
      </div>

      <div class="toolbox-content">
        <div class="content-column">
          <h3 class="sub-title bold">Research</h3>
          <p>User interviews</p>
          <p>Personas</p>
          <p>Wireframes</p>
          <p>Prototyping</p>
          <p>Usability testing</p>
          <p>Journey mapping</p>
        </div>

        <div class="content-column">
          <h3 class="sub-title bold">Design</h3>
          <p>Design thinking</p>
          <p>User experience design</p>
          <p>Visual design</p>
          <p>Responsive web design</p>
          <p>Native app design</p>
          <p>Design systems</p>
          <p>Motion design</p>
        </div>

        <div class="content-column">
          <h3 class="sub-title bold">Technical</h3>
          <p>Figma</p>
          <p>Framer</p>
          <p>Adobe Creative Suite</p>
          <p>HTML</p>
          <p>CSS</p>
          <p>Javascript</p>
        </div>

        <div class="content-column">
          <h3 class="sub-title bold">Organisational</h3>
          <p>Ownership</p>
          <p>Agile</p>
          <p>Scrum</p>
          <p>Workshop facilitator</p>
          <p>Stakeholder management</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import projectdata from "@/assets/data/projects.json";
import ProjectTile from "@/components/ProjectTile.vue";

export default {
  name: "HomePage",
  components: {
    ProjectTile,
  },
  data() {
    return {
      projectdata: projectdata.projects,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/pages/_home.scss";
</style>
