<template>
  <router-link
    :to="`/${projectId}`"
    :class="`project-tile-container ${projectId}`"
    :style="{
      backgroundColor: backgroundProps.color,
      backgroundImage: backgroundProps.image,
      color: tileData.fontColor,
    }"
  >
    <div class="content">
      <div class="project-heading">
        <h1 class="project-title">{{ projectName }}</h1>
        <h2 class="project-subtitle">{{ tileData.description }}</h2>
        <svg
          class="arrow"
          width="24"
          height="23"
          viewBox="0 0 24 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.3636 22.8409L10.0227 20.5227L17.0341 13.5114H0V10.125H17.0341L10.0227 3.125L12.3636 0.795454L23.3864 11.8182L12.3636 22.8409Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>

    <!-- background image (if exists) -->
    <picture
      class="background-image"
      v-if="backgroundProps.image !== '' || backgroundProps.imageMobile !== ''"
    >
      <source
        v-if="backgroundProps.imageMobile !== ''"
        media="(max-width: 768px)"
        :srcset="require(`@/assets/images/${backgroundProps.imageMobile}`)"
      />
      <source
        v-if="backgroundProps.image !== ''"
        media="(min-width: 769px)"
        :srcset="require(`@/assets/images/${backgroundProps.image}`)"
      />
      <img
        v-if="
          backgroundProps.image !== '' || backgroundProps.imageMobile !== ''
        "
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        :style="{
          'object-position': backgroundProps.position,
        }"
      />
    </picture>
  </router-link>
</template>

<script>
export default {
  name: "ProjectTile",
  props: {
    tileData: Object,
    projectId: String,
    projectName: String,
    projectSubtitle: String,
  },
  data: function () {
    return {
      backgroundProps: this.tileData.backgroundProps,
    };
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/components/_projecttile.scss";
</style>
