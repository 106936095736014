import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomePage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Michel Robert | Freelance UX/UI Designer",
      metaTags: [
        {
          name: "description",
          content:
            "Portfolio website for Michel Robert | Freelance UX/UI Designer",
        },
      ],
    },
  },
  {
    path: "/kimo",
    name: "KIMO",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KimoProject.vue"),
    meta: {
      title: "Michel Robert | Freelance UX/UI Designer",
      metaTags: [
        {
          name: "description",
          content:
            "Portfolio website for Michel Robert | Freelance UX/UI Designer",
        },
      ],
    },
  },
  {
    path: "/duikhuis",
    name: "Duikhuis",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DuikhuisProject.vue"),
    meta: {
      title: "Michel Robert | Freelance UX/UI Designer",
      metaTags: [
        {
          name: "description",
          content:
            "Portfolio website for Michel Robert | Freelance UX/UI Designer",
        },
      ],
    },
  },
  {
    path: "/abn",
    name: "ABN AMRO",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AbnProject.vue"),
    meta: {
      title: "Michel Robert | Freelance UX/UI Designer",
      metaTags: [
        {
          name: "description",
          content:
            "Portfolio website for Michel Robert | Freelance UX/UI Designer",
        },
      ],
    },
  },
  {
    path: "/hollandcasino",
    name: "Holland Casino",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HollandCasinoProject.vue"),
    meta: {
      title: "Michel Robert | Freelance UX/UI Designer",
      metaTags: [
        {
          name: "description",
          content:
            "Portfolio website for Michel Robert | Freelance UX/UI Designer",
        },
      ],
    },
  },
  {
    path: "/appietoday",
    name: "Appie Today",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AppieTodayProject.vue"),
    meta: {
      title: "Michel Robert | Freelance UX/UI Designer",
      metaTags: [
        {
          name: "description",
          content:
            "Portfolio website for Michel Robert | Freelance UX/UI Designer",
        },
      ],
    },
  },
  {
    path: "/duikschool",
    name: "Duikschool.nl",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DuikschoolProject.vue"),
    meta: {
      title: "Michel Robert | Freelance UX/UI Designer",
      metaTags: [
        {
          name: "description",
          content:
            "Portfolio website for Michel Robert | Freelance UX/UI Designer",
        },
      ],
    },
  },
  {
    path: "/sensorfact",
    name: "Sensorfact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SensorfactProject.vue"),
    meta: {
      title: "Michel Robert | Freelance UX/UI Designer",
      metaTags: [
        {
          name: "description",
          content:
            "Portfolio website for Michel Robert | Freelance UX/UI Designer",
        },
      ],
    },
  },
  { path: "*", redirect: { name: 'Home' } }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      const element = document.getElementById(to.hash.slice(1));
      if (element) {
        // return window.scrollTo({
        //   top:
        //     element.offsetTop -
        //     document.getElementById("header-menu").offsetHeight,
        //   behavior: "smooth",
        // });
      }
      return window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  /* It will change the title when the router is change*/
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
