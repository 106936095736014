<template>
  <div id="app">
    <!-- header -->
    <HeaderSection />

    <!-- page content -->
    <router-view />

  </div>
</template>

<script>
import HeaderSection from "./components/HeaderSection.vue";

export default {
  name: "App",
  components: {
    HeaderSection
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/settings/_reset.scss";
@import "@/assets/scss/settings/_layout.scss";
</style>
