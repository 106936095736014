<template>
  <section id="header-menu" class="header-container">
    <div class="header-content">
      <div class="header-grid-row">
        <!-- logo -->
        <!-- <div class="logo-container"> -->
        <router-link to="/" class="logo-container">
          <img
            class="site-logo"
            alt="Vue logo"
            src="@/assets/images/logo.png"
          />
          <h2 class="title">Michel Robert</h2>
        </router-link>
        <!-- </div> -->
        <!-- hamburger menu icon -->
        <div
          :class="['menu-burger', { 'menu-burger--open': this.menuOpen }]"
          @click="toggleNav"
        >
          <span class="menu-text">Contact</span>
          <span class="menu-burger-icon"></span>
        </div>
        <!-- menu items -->
        <nav
          :class="[
            'menu-items-container menu__nav',
            { 'menu__nav--open': this.menuOpen },
          ]"
        >
          <div class="menu-item">
            <a href="tel:+31652682486">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M3.21778 6.92444C4.49778 9.44 6.56 11.4933 9.07556 12.7822L11.0311 10.8267C11.2711 10.5867 11.6267 10.5067 11.9378 10.6133C12.9333 10.9422 14.0089 11.12 15.1111 11.12C15.6 11.12 16 11.52 16 12.0089V15.1111C16 15.6 15.6 16 15.1111 16C6.76444 16 0 9.23556 0 0.888889C0 0.4 0.4 0 0.888889 0H4C4.48889 0 4.88889 0.4 4.88889 0.888889C4.88889 2 5.06667 3.06667 5.39556 4.06222C5.49333 4.37333 5.42222 4.72 5.17333 4.96889L3.21778 6.92444Z"
                  fill="currentColor"
                />
              </svg>
              +31 6 52 682 486</a
            >
          </div>

          <div class="menu-item">
            <a href="mailto:hello@michelrobert.nl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
              >
                <path
                  d="M16.2 0H1.8C0.81 0 0.00899999 0.675 0.00899999 1.5L0 10.5C0 11.325 0.81 12 1.8 12H16.2C17.19 12 18 11.325 18 10.5V1.5C18 0.675 17.19 0 16.2 0ZM16.2 3L9 6.75L1.8 3V1.5L9 5.25L16.2 1.5V3Z"
                  fill="currentColor"
                />
              </svg>
              hello@michelrobert.nl</a
            >
          </div>

          <div class="menu-item button">
            <a
              href="https://www.linkedin.com/in/michel-robert-78b46057/"
              target="_blank"
            >
              Linkedin</a
            >
          </div>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeaderSection",
  props: {
    msg: String,
  },
  data: function () {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleNav: function () {
      this.menuOpen = !this.menuOpen;
      this.$emit("toggle", this.menuOpen);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/components/_header.scss";
</style>
